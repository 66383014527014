import React from 'react';
import me from '../../assets/pictures/dog.png';

export interface AboutProps {
}

const About: React.FC<AboutProps> = (props) => {
    return (
        // add on resize listener
        <div className='site-page-content'>
            {/* <img src={me} style={styles.topImage} alt="" /> */}
            <h1 style={{ marginLeft: -2 }}>HAPPY BIRTHDAY</h1>
            <h3>nekoneko</h3>
            <br />
            <div className='text-block'>
                <p>
                    Wish you doing well in grade 11 & 12 ~ enjoying Mahiro, Yuzusoft, EXE... btw
                </p>
            </div>
            <div className='text-block'>
                <div className='captioned-image'>
                    <img src={me} style={styles.image} alt='' />
                </div>


            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    contentHeader: {
        marginBottom: 16,
        fontSize: 48
    },
    image: {
        height: 'auto',
        width: '100%'
    },
    topImage: {
        height: 'auto',
        width: '100%',
        marginBottom: 32
    },
    verticalImage: {
        alignSelf: 'center',
        // width: '80%',
        marginLeft: 32,
        flex: 0.8,

        alignItems: 'center',
        // marginBottom: 32,
        textAlign: 'center',
        flexDirection: 'column'
    }
};

export default About;
