import React, {useState} from 'react';
import { Link } from '../general';

import { useNavigate } from 'react-router';
import Confetti from 'react-confetti'
export interface HomeProps {}

const Home: React.FC<HomeProps> = (props) => {
    const navigate = useNavigate();
    const [isConfettiActive, setIsConfettiActive] = useState(false); // Add state for controlling the confetti

    const goToContact = () => {
        navigate('/contact');
    };
    const handleMouseMove = () => {
        setIsConfettiActive(true);
    };

    const handleMouseLeave = () => {
        setIsConfettiActive(false);
    };

    const onConfettiComplete = () => {
        setIsConfettiActive(false);
    };


    return (
        <div style={styles.page}
             onMouseMove={handleMouseMove}
             onMouseLeave={handleMouseLeave}
        >
            {isConfettiActive && <Confetti onConfettiComplete={onConfettiComplete} />}
            <div style={styles.header}>
                <h1 style={styles.name}>HAPPY BIRTHDAY !</h1>
                <h2>keaidedoubi · nekoneko</h2>
            </div>

            <div style={styles.buttons}>
                <Link containerStyle={styles.link} to="about" text="ABOUT" />
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    page: {
        left: 0,
        right: 0,
        top: 0,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        backgroundImage: 'url(https://s2.loli.net/2023/02/23/HmdLejGCB69q5yW.png)',
        backgroundSize: 'cover',
    },
    header: {
        textAlign: 'center',
        marginBottom: 64,
        marginTop: 64,

        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttons: {
        justifyContent: 'space-between',
    },
    image: {
        width: 800,
    },
    link: {
        padding: 16,
    },
    nowHiring: {
        backgroundColor: 'red',
        padding: 16,
    },
    forHireContainer: {
        marginTop: 64,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    name: {
        fontSize: 72,
        marginBottom: 16,
        lineHeight: 0.9,
        backgroundImage: '-webkit-linear-gradient(315deg, #42d392 25%, #647eff)',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
};

export default Home;
